import { CropConstants } from 'utils/constants/Crop';

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const formatPhoneNumber = (phoneNumber: string, phoneDialCode: string): string => {
  if (isNaN(Number(phoneNumber)) || isNaN(Number(phoneDialCode))) {
    return '';
  }

  let formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

  if (formattedPhoneNumber.startsWith(phoneDialCode)) {
    formattedPhoneNumber = formattedPhoneNumber.substring(phoneDialCode.length);
  }

  formattedPhoneNumber = formattedPhoneNumber.match(/.{1,3}/g)?.join(' ') ?? '';
  return `+${phoneDialCode} ${formattedPhoneNumber}`;
};

export const averageYieldConvertedValue = (
  country: string | undefined,
  avgYield: number | undefined,
  crop?: string | undefined
): number | string => {
  if (
    (country === CropConstants.BULGARIA_ISO2 ||
      country === CropConstants.BULGARIA_CODE ||
      country === CropConstants.Bulgaria) &&
    avgYield
  ) {
    return avgYield * 10;
  }
  return avgYield?.toFixed(1) || 0;
};

export const seedRateConvertedValue = (country: string | undefined, seedRate: string): string => {
  return country === CropConstants.BULGARIA_ISO2 ||
    country === CropConstants.BULGARIA_CODE ||
    country === CropConstants.Bulgaria
    ? (Number(seedRate) / 10).toString()
    : seedRate;
};

export const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};
