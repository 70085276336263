import { useCallback, useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { colors, Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { Map as MapReact, useMap, Marker as MarkerReact, Source, Layer } from 'react-map-gl';
import lookup from 'country-code-lookup';
import { LngLat, GeoJSONFeature, MapMouseEvent, Popup } from 'mapbox-gl';
import { useTranslation } from 'react-i18next';
import {
  clusterLayer,
  clusterCountLayer,
  TrialingInfoPinIcon,
  unclusteredPointLayerIcon,
  clusterCountBackgroundLayer,
} from './layers';

import { CustomControl } from 'components/Maps/Controls/CustomControl';
import IconClose from 'components/Icons/IconClose';
import {
  MapContainer,
  StyledDiv,
  StyledCloseContainer,
  SectionContainerStyled,
  StyledUserMarker,
  StyledNotificationContent,
  StyledSpinner,
  TabsContainer,
  StyledTabs,
  StyledFullscreenDiv,
} from './TrialingInformation.styles';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import { LocationControl } from 'components/Maps/Controls/LocationControl';
import {
  TrialingContent as SectionContent,
  TrialingInformationTitle as Title,
  Text,
} from 'pages/RecommendationV2/V2Common.styles';
import { AgronomicInputs, TrialProduct } from 'base/types/RecommendationCropwise';
import { YieldData } from 'base/types/BreakoutsGlobalResponse';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { browserDetector } from 'utils/helpers/browser';
import { BrowserConstants } from 'utils/constants/BrowserConstants';
import openMapNotification from 'utils/openMapNotification';
import { pointInBbox, getCentroidByCountry, getZoomLevelByCountry } from 'utils/helpers/geospatial';
import { useAppState } from 'context/AppState';
import debounce from 'utils/debounce';
import delay from 'utils/delay';
import zoom_gesture from 'assets/icons/zoom_gesture.svg';
import track from 'utils/amplitudeWrapper';
import { useBreakpoint } from 'hooks';
import {
  SkeletonRowStyled,
  SkeletonTitleStyled,
  SkeletonMobileContainer,
  SkeletonMobileBottomContainer,
  SkeletonMobileTopContainer,
} from './SoilCharacteristics.styles';
import TrialingInformationModal from './TrialingInformationModal';
import { CropConstants } from 'utils/constants/Crop';
import { notification } from 'antd';

import ZoomControl from './../../../pages/RecommendationV2/Sections/Controls/ZoomControl/ZoomControl';
import FullscreenControl from 'components/Maps/Controls/FullscreenControl/FullscreenControl';
import {
  StyledDivText,
  StyledYearSection,
  StyledYearButton,
  StyledDivider,
  StyledRow,
  StyledDivValue,
  StyledPopup,
  StyledProductName,
} from './TrialingInformationModal.styles';
import { createRoot } from 'react-dom/client';
import { averageYieldConvertedValue } from 'utils/helpers/converter';

interface TrialindInformationProps {
  sectionRef?: any;
  isLoading?: boolean;
  isHumanUser?: boolean;
}

const browserDetected = browserDetector();
let locationURL = BrowserConstants.CHROME_LOCATION_URL;
switch (browserDetected) {
  case BrowserConstants.FIREFOX:
    locationURL = BrowserConstants.FIREFOX_LOCATION_URL;
    break;
  case BrowserConstants.SAFARI:
    locationURL = BrowserConstants.SAFARI_LOCATION_URL;
    break;
  case BrowserConstants.EDGE:
    locationURL = BrowserConstants.EDGE_LOCATION_URL;
    break;
  case BrowserConstants.OPERA:
    locationURL = BrowserConstants.OPERA_LOCATION_URL;
    break;
}

interface PopupContentProps {
  trialedDetail?: GeoJSONFeature[] | null;
  trial: string[];
  metricLabel: () => string;
  country: string;
}

const PopupContent: React.FC<PopupContentProps> = ({
  trialedDetail,
  trial,
  metricLabel,
  country,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(trial?.[0] ?? '');
  const [selectedTrial, setSelectedTrial] = useState<YieldData | null>(null);

  useEffect(() => {
    const getTrialsForYear = (year: string) => {
      return trialedDetail?.filter(
        (trialData) => trialData?.properties?.trialYear === parseInt(year)
      );
    };

    const trialsForSelectedYear = getTrialsForYear(selectedYear);

    const trialMatch = /\(trial (\d+)\)/.exec(selectedYear);
    const trialNumber = trialMatch ? parseInt(trialMatch[1]) : 0;
    const index = trialNumber > 0 ? trialNumber - 1 : 0;

    const newTrial =
      trialsForSelectedYear?.[index]?.properties || trialsForSelectedYear?.[0]?.properties;

    setSelectedTrial(newTrial as YieldData);
  }, [selectedYear, trialedDetail]);

  const handleYearClick = useCallback((year: string) => {
    setSelectedYear(year);
  }, []);

  const yearButtons = useMemo(() => {
    return trial.map((year) => (
      <StyledYearButton
        key={year}
        onClick={() => handleYearClick(year)}
        isSelected={year === selectedYear}
      >
        {year}
      </StyledYearButton>
    ));
  }, [trial, selectedYear, handleYearClick, i18n.language]);

  return (
    <StyledPopup>
      <StyledProductName>{selectedTrial?.commercialName}</StyledProductName>
      <StyledDivText>{t('Select a period to view info')}: </StyledDivText>{' '}
      <StyledYearSection>{yearButtons}</StyledYearSection>
      <StyledDivider />
      <StyledRow>
        <StyledDivText>{t('Trial Year')}</StyledDivText>
        <StyledDivValue>{selectedTrial?.trialYear}</StyledDivValue>
      </StyledRow>
      <StyledRow>
        <StyledDivText>{t('Average yield results in the trial')}</StyledDivText>
        <StyledDivValue>{`${averageYieldConvertedValue(country, selectedTrial?.avgCheckYield)} ${t(
          metricLabel()
        )}`}</StyledDivValue>
      </StyledRow>
      <StyledRow>
        <StyledDivText>
          {selectedTrial?.commercialName} {t('yield result')}
        </StyledDivText>
        <StyledDivValue>{`${averageYieldConvertedValue(country, selectedTrial?.avgEntryYield)} ${t(
          metricLabel()
        )}`}</StyledDivValue>
      </StyledRow>
      <StyledRow>
        <StyledDivText>{t('Absolute yield difference')}</StyledDivText>
        <StyledDivValue>{`${averageYieldConvertedValue(country, selectedTrial?.yieldDiff)} ${t(
          metricLabel()
        )}`}</StyledDivValue>
      </StyledRow>
      <StyledRow>
        <StyledDivText>{t('Relative yield difference')}</StyledDivText>
        <StyledDivValue>{selectedTrial?.yieldDiffPercentage}%</StyledDivValue>
      </StyledRow>
    </StyledPopup>
  );
};

export default function TrialingInformation(props: TrialindInformationProps) {
  const { sectionRef, isLoading, isHumanUser } = props;
  const { trialingMap: map } = useMap();
  const mapContainerRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const [products, setProducts] = useState<TrialProduct[] | null>();
  const [productLocations, setProductLocations] = useState({});
  const [markersCoordinates, setMarkersCoordinates] = useState<YieldData[] | null>();
  const [intersectLocation, setIntersectLocation] = useState(false);
  const [showUserMarker, setShowUserMarker] = useState(false);
  const [latitudeUser, setLatitudeUser] = useState(MapsConstants.LATITUDE_DEFAULT);
  const [longitudeUser, setLongitudeUser] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const [userLocation, setUserLocation] = useState<LngLat | null>();
  const [showNotification, setShowNotification] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [showProducts, setShowProducts] = useState(false);
  const [trialedDetail, setTrialedDetail] = useState<GeoJSONFeature[] | null>();
  const { isMobile, landscape } = useBreakpoint();
  const isMobilePortraitOrDesktop = (isMobile && !landscape) || !isMobile;
  const [agronomicInputs, setAgronomicInputs] = useState<AgronomicInputs>();
  const [trialYears, setTrialYears] = useState<string[]>();
  const isBotValid = ['local', 'dev', 'qa', 'uat'].includes(process.env.REACT_APP_ENV as string);

  const popupRef = useRef<Popup>(
    new Popup({
      closeOnClick: false,
      closeButton: true,
      maxWidth: '440px',
      offset: [0, -18],
      focusAfterOpen: false,
    })
  );
  const [t] = useTranslation();
  // translate(-50%, 0px) translate(794px, 66px);
  const {
    apiData: { recommendationCropwise, breakoutsGlobal, productCatalog },
  } = useAppState();
  const isUserNotYetVerified = isHumanUser === undefined;

  type ProductLocationKey = keyof typeof productLocations;

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const agroInput =
        recommendationCropwise.recommendations[0]?.multi_field?.config?.agronomicInputs;
      setAgronomicInputs(agroInput);
    }
  }, [recommendationCropwise]);

  const mapInititalState = {
    zoom: MapsConstants.ZOOM_DEFAULT,
    latitude: MapsConstants.LATITUDE_DEFAULT,
    longitude: MapsConstants.LONGITUDE_DEFAULT,
  };

  const metricLabel = () => {
    if (recommendationCropwise?.country === CropConstants.Bulgaria) {
      return 'kg/decare';
    }
    return agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)';
  };

  const zoomIn = () => {
    map?.getMap().zoomIn();
    track('recommendation interaction', { 'map interaction zoom': 'zoomIn' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });
  };

  const zoomOut = () => {
    map?.getMap().zoomOut();
    track('recommendation interaction', { 'map interaction zoom': 'zoomOut' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });
  };

  function processFeatures(features: GeoJSONFeature[]) {
    const yearCounts: Record<number, number> = {};
    const trialYears: Record<number, string[]> = {};

    features.forEach((feature) => {
      if (feature.properties && typeof feature.properties.trialYear === 'number') {
        const year = feature.properties.trialYear;
        yearCounts[year] = (yearCounts[year] || 0) + 1;
      }
    });

    features.forEach((feature) => {
      if (feature.properties && typeof feature.properties.trialYear === 'number') {
        const year = feature.properties.trialYear;
        if (!trialYears[year]) {
          trialYears[year] = [];
        }
        if (yearCounts[year] > 1) {
          trialYears[year].push(`${year} (${t('trial')} ${trialYears[year].length + 1})`);
        } else {
          trialYears[year].push(`${year}`);
        }
      }
    });
    return Object.entries(trialYears)
      .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
      .flatMap(([year, trials]) => (yearCounts[Number(year)] > 1 ? trials : [year]));
  }

  const onClickMarkerHandler = (features: GeoJSONFeature[]) => {
    const trial = processFeatures(features);
    setTrialYears(trial);
    const yieldData = features[0].properties as YieldData;
    track('recommendation interaction', { 'map interaction pin': 'opened' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });

    if (!isMobile && map && yieldData) {
      const popupNode = document.createElement('span');
      if (popupNode) {
        const root = createRoot(popupNode);
        root.render(
          <PopupContent
            trialedDetail={features}
            trial={trial}
            metricLabel={metricLabel}
            country={recommendationCropwise?.country ?? ''}
          />
        );
      } else {
        console.error('Popup container not found');
      }

      const calculateOffset = (): [number, number] => {
        const markerPixel = map.project([yieldData.longitude, yieldData.latitude]);
        const mapHeight = map.getContainer().offsetHeight;
        return [0, markerPixel.y > mapHeight / 2 ? 5 : -5];
      };

      popupRef.current
        .setLngLat({ lng: yieldData.longitude, lat: yieldData.latitude })
        .setOffset(calculateOffset())
        .setDOMContent(popupNode);

      setTimeout(() => popupRef.current.addTo(map.getMap()), popupRef.current.isOpen() ? 0 : 50);
    } else {
      setTrialedDetail(features);
    }
  };

  const convertToGeoJSON = (data: any): any => {
    const features = data.map((item: any) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
        properties: item,
      };
    });
    return {
      type: 'FeatureCollection',
      features: features,
    };
  };

  const markersGeoJson = convertToGeoJSON(markersCoordinates || []);

  const handleTouchStart = (event: TouchEvent) => {
    if (event.touches.length >= 2) {
      notification.close('zoomInfoNotification');
    }
  };

  useEffect(() => {
    return () => {
      const mapContainer = document.getElementById('trialingMap');
      if (mapContainer) {
        mapContainer.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, []);

  const showDisplayNotification = () => {
    openMapNotification({
      id: 'zoomInfoNotification',
      className: 'toast-notification-zoomInfo',
      key: 'zoomInfoNotification',
      duration: 0,
      msg: t('Use two fingers to interact with map'),
      placement: 'top',
      icon: <img src={zoom_gesture} alt="No img found" style={{ position: 'absolute' }} />,
      style: {
        color: '#FFFFFF',
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        backgroundColor: 'rgba(20, 21, 28, 0.9)',
      },
      container: document.getElementById('trialingMap'),
    });
  };

  const onLoadMapHandler = () => {
    setIsMapLoaded(true);
    const mapContainer = document.getElementById('trialingMap');
    if (mapContainer) {
      mapContainer.addEventListener('touchstart', handleTouchStart);
    }

    if (isMobile) {
      showDisplayNotification();
      map?.addControl(new CustomControl('locationControlTrailing'), 'bottom-right');
    } else {
      map?.addControl(new CustomControl('zoomControlTrialing'), 'bottom-right');
      map?.addControl(new CustomControl('locationControlTrailing'), 'bottom-right');
      map?.addControl(new CustomControl('fullscreenControl'), 'bottom-right');
    }
    map?.on('idle', () => {
      setShowSpinner(false);
    });

    map?.on('click', 'unclustered-point', handleMapClick);

    map?.on('click', 'clusters', (e) => {
      const features = map?.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      if (features && features.length > 0 && features[0]?.properties && features[0].geometry) {
        const zoomLevel = map?.getZoom();
        map.easeTo({
          center: [e.lngLat.lng, e.lngLat.lat],
          zoom: zoomLevel + 1,
        });
      }
    });

    map?.loadImage(`data:image/png;base64,${TrialingInfoPinIcon}`, (error, image) => {
      if (error) {
        throw error;
      }
      map?.addImage('custom-marker-pin', image as HTMLImageElement);
    });
    map?.getMap().setMaxZoom(MapsConstants.ZOOM_IN_LIMIT_TRIALING_INFO_MAP);
  };

  function handleMapClick(e: MapMouseEvent) {
    const features = map?.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
    if (!features || features.length === 0 || !features[0]?.properties) {
      return;
    }

    onClickMarkerHandler(features);
  }

  const onClickProductHandler = async (index: number) => {
    if (selectedProduct === index) {
      return;
    }

    if (products && productLocations) {
      const productName = products[index].productName;
      track('recommendation interaction', { 'map interaction product': productName });
      isMobile && track('recommendation interaction', { 'cellphone map interaction': true });

      popupRef.current?.remove();
      setShowSpinner(true);
      setSelectedProduct(index);
      setMarkersCoordinates([]);
      await delay(50);
      const markers: any[] = productLocations[productName as ProductLocationKey];

      if (markers.length) {
        setMarkersCoordinates(markers);
      } else {
        setShowSpinner(false);
      }
    }
  };

  const checkIntersection = () => {
    if (!userLocation) {
      return;
    }
    // eslint-disable-next-line
    const bounds = map?.getBounds();
    if (bounds) {
      setIntersectLocation(pointInBbox(userLocation, bounds));
    }
  };

  const onLocationClick = () => {
    setIsLocationEnabled(true);
  };

  const onLocationErrorHandler = () => {
    if (!showNotification) {
      return;
    }

    openMapNotification({
      id: 'preciseLocation',
      className: 'toast-notification-without-icon',
      msg: (
        <StyledNotificationContent>
          <NotificationTextTranslation text={'Your precise location could not be determined.'} />{' '}
          <a target="_blank" rel="noreferrer" href={locationURL}>
            <NotificationTextTranslation text={'LEARN MORE'} />
          </a>
        </StyledNotificationContent>
      ),
      placement: 'bottom',
      duration: 10,
      width: 480,
      closeIcon: <IconClose color={colors.neutral40} width={18} height={18} />,
      onClose: () => setShowNotification(true),
      icon: <></>,
      container: document.getElementById('trialingMap'),
    });
    setShowNotification(false);
  };

  const fitBounds = useCallback(() => {
    if (recommendationCropwise?.country) {
      const code = lookup.byCountry(
        recommendationCropwise.country === 'Russian Federation'
          ? 'Russia'
          : recommendationCropwise.country
      );
      const countryCodeISO3 = code?.iso3 ?? '';
      const countryCentroid = getCentroidByCountry(countryCodeISO3);
      if (countryCentroid) {
        const {
          geometry: { coordinates: center },
        } = countryCentroid;
        const zoomLevel = getZoomLevelByCountry(countryCodeISO3);
        map?.flyTo({
          center: [center[0], center[1]],
          essential: true,
          zoom: zoomLevel,
        });
      }
    }
  }, [map, recommendationCropwise?.country]);

  useLayoutEffect(() => {
    if (!isHumanUser) {
      return;
    }
    function closePopup() {
      if (!popupRef.current.isOpen()) {
        return;
      }

      const ele: any = document.querySelector('.mapboxgl-popup-close-button');
      ele?.click?.();
    }

    map?.on('click', closePopup);

    return () => {
      map?.off('click', closePopup);
    };
  }, [isHumanUser, map]);

  useLayoutEffect(() => {
    if (!isHumanUser) {
      return;
    }
    const mapRef = map?.getMap();
    const resizer = new ResizeObserver(
      debounce(() => {
        // Recenter the map on any container size change
        mapRef?.resize();
      })
    );

    if (mapContainerRef.current) {
      resizer.observe(mapContainerRef.current);
    }

    return () => {
      resizer.disconnect();
    };
  }, [map, isHumanUser]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations) {
      const productsInfo = recommendationCropwise?.recommendations[0]?.products ?? [];
      const trialedProducts = productsInfo
        .filter((seed) => seed.product_name !== 'None')
        .map((recommendedProduct) => {
          const product = productCatalog?.find(
            (p) => p.productName === recommendedProduct.product_name
          );
          return {
            productName: product?.productName,
            commercialName: product?.commercialName,
          };
        }) as TrialProduct[];

      setProducts(trialedProducts);

      if (breakoutsGlobal && trialedProducts.length) {
        trialedProducts.forEach((item, index) => {
          // processing product locations
          if (breakoutsGlobal[item.productName]) {
            const productBreakouts = breakoutsGlobal[item.productName];
            const { locationsYieldData } = productBreakouts;
            const AVG_CHECK_YIELD_DISPLAY_RULE =
              agronomicInputs?.extraInfo?.yieldRangeUnit === 'q/ha' ? 20 : 2; // Exclude all the yields bellow 2 t/ha (20 q/ha) from result map
            const locations = locationsYieldData
              .map(
                (yieldItem) =>
                  ({
                    longitude: yieldItem.ln,
                    latitude: yieldItem.lt,
                    commercialName: item.commercialName,
                    avgCheckYield: yieldItem.acy,
                    avgEntryYield: yieldItem.aey,
                    yieldDiff: yieldItem.yd,
                    yieldDiffPercentage: yieldItem.ydp,
                    trialYear: yieldItem.ty,
                  } as YieldData)
              )
              .filter((item) => {
                if (recommendationCropwise.country !== 'BR') {
                  return (
                    item.latitude &&
                    item.longitude &&
                    item.avgCheckYield &&
                    item.avgCheckYield >= AVG_CHECK_YIELD_DISPLAY_RULE
                  );
                } else {
                  return item;
                }
              });
            setProductLocations((prevState) => ({
              ...prevState,
              [item.productName]: locations,
            }));
            if (index === 0) {
              setMarkersCoordinates(locations);
              fitBounds();
            }
          }
        });
      }
    }
    /* eslint-disable-next-line */
  }, [recommendationCropwise, breakoutsGlobal, fitBounds]);

  const onUserLocatedHandler = (lngLat: LngLat) => {
    if (map) {
      setLongitudeUser(lngLat.lng);
      setLatitudeUser(lngLat.lat);
      setShowUserMarker(true);
      setUserLocation(lngLat);
      if (isMobile && markersCoordinates) {
        const allCoordinates = markersCoordinates.length > 0 ? [...markersCoordinates] : [];
        allCoordinates.push({ latitude: lngLat.lat, longitude: lngLat.lng });

        fitBounds();
      } else {
        map.flyTo({
          center: [lngLat.lng, lngLat.lat],
          essential: true,
          zoom: 15,
        });
      }
    }
  };

  const showControls = (isMobile && showProducts) || !isMobile;

  const onCloseInformationHandler = () => {
    setShowProducts(false);

    const closeButtons = Array.from(
      document.querySelectorAll('.syt-antd-notification-notice-close')
    );
    closeButtons.forEach((closeButton) => {
      const element = closeButton as HTMLElement;
      const spanElement = element?.firstChild as HTMLElement;
      spanElement.click();
    });
  };

  const onCloseTrialedDetailHandler = () => {
    setTrialedDetail(null);
  };

  const isMarkerVisible = useCallback(() => {
    return isMobile ? showUserMarker && showProducts : showUserMarker;
  }, [isMobile, showProducts, showUserMarker]);

  const SkeletonLoader = useMemo(() => {
    if (isMobile) {
      return (
        <SkeletonMobileContainer>
          <SkeletonMobileTopContainer>
            <SkeletonTitleStyled width="80%" active size="large" />
          </SkeletonMobileTopContainer>
          <SkeletonMobileBottomContainer>
            <SkeletonRowStyled active size="large" />
            <SkeletonRowStyled active size="large" />
            <SkeletonRowStyled active size="large" />
          </SkeletonMobileBottomContainer>
        </SkeletonMobileContainer>
      );
    }
    return <Skeleton active paragraph={{ rows: 11, width: '100%' }} />;
  }, [isMobile]);
  // Hide the trialing info section if the user is a bot. Show loader if the user is not yet verified.

  const isValid = isHumanUser || isBotValid;
  return isValid ? (
    <SectionContainerStyled ref={sectionRef} id="trialingInformationContainer">
      <SectionContent>
        <Title>{t('Trialing Information')}</Title>
        <Text data-testid="trialing description">{t('Trialing Information description')}</Text>
      </SectionContent>
      {isUserNotYetVerified || isLoading ? (
        SkeletonLoader
      ) : (
        <>
          <TabsContainer
            data-testid="tabsContainer"
            isMobilePortraitOrDesktop={isMobilePortraitOrDesktop}
          >
            <StyledTabs
              items={products?.map((p) => ({
                key: p.productName,
                label: p.commercialName,
                children: <div />,
              }))}
              activeKey={products?.[selectedProduct]?.productName}
              onChange={(key) => {
                const index = products?.findIndex((p) => p.productName === key) ?? 0;
                onClickProductHandler(index);
              }}
            />
          </TabsContainer>

          <MapContainer
            id="map-container"
            fullScreen={false}
            ref={mapContainerRef}
            isMobile={isMobile}
          >
            <MapReact
              id="trialingMap"
              data-testid="trialingMap"
              scrollZoom={false}
              mapStyle={MapsConstants.MAP_STYLE_URL}
              initialViewState={mapInititalState}
              onLoad={onLoadMapHandler}
              minZoom={1}
              onMove={checkIntersection}
              cooperativeGestures={true}
            >
              <div
                id="mapContainer"
                data-testid="mapContainer"
                style={{ display: isMapLoaded ? 'block' : 'none' }}
              >
                {!isMobile && (
                  <>
                    <StyledFullscreenDiv
                      id="fullscreenControl"
                      className={showControls ? '' : 'hidden'}
                    >
                      <FullscreenControl mapRef={map} />
                    </StyledFullscreenDiv>
                    <StyledDiv id="zoomControlTrialing" className={showControls ? '' : 'hidden'}>
                      <ZoomControl
                        buttonPlusProps={{ 'aria-label': 'Zoom In', onClick: zoomIn }}
                        buttonMinusProps={{ 'aria-label': 'Zoom Out', onClick: zoomOut }}
                        style={{
                          backgroundColor: colors.neutral90,
                          borderRadius: '8px',
                        }}
                        showTooltip={false}
                      />
                    </StyledDiv>
                  </>
                )}

                <StyledCloseContainer id="closeControl">
                  <IconClose
                    data-testid="closeControl"
                    color={colors.neutral00}
                    width={20}
                    height={20}
                    onClick={onCloseInformationHandler}
                  />
                </StyledCloseContainer>

                <StyledDiv data-testid="locationControl" id="locationControlTrailing">
                  <LocationControl
                    buttonProps={{
                      'aria-label': 'Find my location',
                    }}
                    onUserLocated={onUserLocatedHandler}
                    onLocationError={onLocationErrorHandler}
                    isLocationEnabled={isLocationEnabled}
                    mapIntersected={intersectLocation}
                    onClick={onLocationClick}
                    style={{ borderRadius: '8px' }}
                  />
                </StyledDiv>
              </div>
              <Source
                id="trialingMapSource"
                type="geojson"
                data={markersGeoJson}
                cluster={true}
                clusterMaxZoom={4}
                clusterMinPoints={4}
                clusterRadius={30}
              >
                <Layer {...unclusteredPointLayerIcon} />
                <Layer {...clusterLayer} />
                <Layer {...clusterCountBackgroundLayer} />
                <Layer {...clusterCountLayer} />
              </Source>
              {isMarkerVisible() && (
                <MarkerReact
                  style={{ zIndex: 1 }}
                  latitude={latitudeUser}
                  longitude={longitudeUser}
                >
                  <StyledUserMarker />
                </MarkerReact>
              )}
              {trialedDetail && (
                <TrialingInformationModal
                  info={trialedDetail}
                  trialYears={trialYears}
                  onClose={onCloseTrialedDetailHandler}
                />
              )}
            </MapReact>
            {showSpinner && <StyledSpinner />}
          </MapContainer>
        </>
      )}
    </SectionContainerStyled>
  ) : null;
}
