/* eslint-disable array-bracket-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { LngLat, StyleSpecification, LngLatBounds } from 'mapbox-gl';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import track from 'utils/amplitudeWrapper';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { ZoomControl } from './Controls/ZoomControl';
import { LocationControl } from './Controls/LocationControl';
import { CustomControl } from './Controls/CustomControl';
import IconClose from 'components/Icons/IconClose';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import { CenterMapControl } from './Controls/CenterMapControl/CenterMapControl';

import { browserDetector } from 'utils/helpers/browser';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { BrowserConstants } from 'utils/constants/BrowserConstants';

import IconCloseRounded from 'components/Icons/IconCloseRounded';
import { StyledMarker, StyledNotificationContent, StyledDivGeocoder } from './Maps.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.less';

import { Map as MapReact, useMap, Marker as MarkerReact, MapRef } from 'react-map-gl';

import DrawableMap from '../DrawableMap/DrawableMap';

import { useAppState, useAppDispatch } from 'context/AppState';
import { pointInBbox, pointInCountry } from 'utils/helpers/geospatial';
import { useBreakpoint } from 'hooks';
import openMapNotification from 'utils/openMapNotification';
import { useTranslation } from 'react-i18next';
import { useFlowActions } from 'context/actions/flowActions';
import { useMapActions } from 'context/actions/mapActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';

import { Field } from 'context/store/recommendationFormReducer';

import { FlowSteps, ModeTypes } from 'context/store/flowReducer';

import MapToolsWrapper from 'containers/MapToolsWrapper';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import { Spinner } from 'components/Spinner';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { StepsControl } from './Controls/StepsControl';
import { notification } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Hands from 'assets/icons/hands.svg';
import { getCountryCropRegion } from 'utils/constants/CountryData';
import { processGeocodingResult } from 'utils/helpers/geocodingResult';
import { trackGTMEvent } from 'utils/createGTMEvent';

interface IProps {
  mapId: string;
  latitude?: number;
  longitude?: number;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  mapStyle?: string | StyleSpecification;
  onMove?: () => void;
  stepControlsProps?: any;
}

const browserDetected = browserDetector();
let locationURL = BrowserConstants.CHROME_LOCATION_URL;
switch (browserDetected) {
  case BrowserConstants.FIREFOX:
    locationURL = BrowserConstants.FIREFOX_LOCATION_URL;
    break;
  case BrowserConstants.SAFARI:
    locationURL = BrowserConstants.SAFARI_LOCATION_URL;
    break;
  case BrowserConstants.EDGE:
    locationURL = BrowserConstants.EDGE_LOCATION_URL;
    break;
  case BrowserConstants.OPERA:
    locationURL = BrowserConstants.OPERA_LOCATION_URL;
    break;
  default:
    break;
}

const getFieldById = ({ fieldId, fields }: { fieldId: string | number; fields: Field[] }) =>
  fields.find((field) => field.id.toString() === fieldId.toString());

const Maps = ({
  mapId,
  latitude,
  longitude,
  zoom = MapsConstants.ZOOM_DEFAULT,
  minZoom = 0,
  maxZoom = 22,
  mapStyle = MapsConstants.MAP_STYLE_URL,
  onMove,
  stepControlsProps,
}: IProps): JSX.Element => {
  const {
    flow,
    recommendationForm: {
      fields,
      tempBoundaryField,
      fieldIdSelected,
      fieldSelected,
      focusFieldId,
      isAtleastAVertex,
      countryCode,
    },
    apiData: { fieldSoilsLoading, fieldSoilsError, arableLandLoading },
  } = useAppState();
  const { setMapToolsOptionSelected } = useMapActions();
  const {
    setShowMapTools,
    setShowEnvironmentDrawer,
    setShowEnvironmentInformationModal,
    setIsMapBounded,
    setShowEnvironmentPopup,
    setUserCountryCode,
  } = useFlowActions();
  const { setTempBoundaryField, setFieldSelected, setFieldIdSelected, setCountryCode } =
    useRecommendationFormActions();
  const appDispatcher = useAppDispatch();
  const [displayControls, setDisplayControls] = useState(false);
  const [userLocation, setUserLocation] = useState<LngLat | null>();
  const [intersectLocation, setIntersectLocation] = useState(false);
  const [isFieldsFitBoundsBlocked, setIsFieldsFitBoundsBlocked] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const [showUserMarker, setShowUserMarker] = useState(false);
  const [centerMapButtonDisable, setCenterMapButtonDisable] = useState(true);
  const [latitudeUser, setLatitudeUser] = useState(MapsConstants.LATITUDE_DEFAULT);
  const [longitudeUser, setLongitudeUser] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const geocoderRef = useRef<MapboxGeocoder | null>(null);
  const [t] = useTranslation();
  const { i18n } = useTranslation();
  const ApiDataActions = useApiDataActions();
  const fieldSoilsCache = useRef<any[]>([]);
  const screens = useBreakpoint();
  const [map, setMap] = useState<MapRef>();
  const [centerMapEnabled, setCenterMapEnabled] = useState(false);
  const {
    showFieldForm,
    currentModeType,
    isLocationEnabled,
    showEnvironmentInformationModal,
    isMapBounded,
    currentStep,
    showEnvironmentPopup,
    isDrawingBoundaries,
    showInvalidBoundaryModal,
    localCountry,
  } = flow;
  const { isMobile, portrait, landscape, isDesktop } = screens;
  const flags = useFlags();
  const isBrazilEnable = flags.isBrazilEnable;
  const areExistingFieldsFocusedOnce = useRef(false);

  const [coordsEvents, setCoordsEvents] = useState<{
    clicked?: mapboxgl.MapMouseEvent;
    cursor?: mapboxgl.MapMouseEvent;
  }>();
  const tempBoundaryRef = useRef<number>(0);
  useEffect(() => {
    if (
      currentModeType !== ModeTypes.EDITING &&
      currentModeType !== ModeTypes.DELETING &&
      tempBoundaryField &&
      tempBoundaryRef.current !== tempBoundaryField?.properties?.id &&
      !showEnvironmentPopup
    ) {
      const [firstNode] = tempBoundaryField?.geometry.coordinates[0] || [];
      const [lng, lat] = firstNode;
      const countryCode = pointInCountry({ lng, lat }, flags);
      console.log('countryCode MapsDrawBoundary', countryCode);
      setCountryCode({ countryCode: countryCode ?? '' });
      setShowEnvironmentPopup({ show: true });
      trackGTMEvent('user_draw_field', countryCode, localCountry);
    }
    tempBoundaryRef.current = tempBoundaryField?.properties?.id;
  }, [tempBoundaryField]);

  const { flowMap, flowMapMobile } = useMap();
  const mapInteractive = currentStep === FlowSteps.STEP1;

  const interactiveProps = {
    zoomEnabled: mapInteractive,
    scrollEnabled: mapInteractive,
    pitchEnabled: mapInteractive,
    rotateEnabled: mapInteractive,
    dragPan: mapInteractive,
    dragRotate: mapInteractive,
    doubleClickZoom: mapInteractive,
  };

  useEffect(() => {
    if (focusFieldId) {
      const bounds = new LngLatBounds();
      const fieldToFocus = fields.filter((item) => `${item.id}` === `${focusFieldId}`)[0];

      fieldToFocus.boundary.geometry.coordinates[0].forEach((coords) => {
        bounds.extend(new LngLat(coords[0], coords[1]));
      });

      map?.fitBounds(bounds, {
        padding: {
          top: isMobile ? 90 : 100,
          bottom: isMobile ? 290 : 50,
          left: isMobile ? 50 : 140,
          right: isMobile ? 50 : 140,
        },
        maxZoom: isMobile ? 16 : 18,
      });
      setIsFieldsFitBoundsBlocked(true);
    }
  }, [focusFieldId]);

  useEffect(() => {
    const bounds = new LngLatBounds();
    let runFitBounds = false;

    // Focus only one field with WIP
    if (
      tempBoundaryField &&
      (currentModeType === ModeTypes.CONFIRMING_AFTER_EDIT ||
        currentModeType === ModeTypes.NO_ACTION ||
        (currentModeType === ModeTypes.EDITING && !isMapBounded))
    ) {
      setIsMapBounded({ bounded: true });
      tempBoundaryField?.geometry.coordinates[0].forEach((coords) => {
        bounds.extend(new LngLat(coords[0], coords[1]));
      });
      runFitBounds = true;
    }

    if (fields.length && currentStep === FlowSteps.STEP2) {
      // Special case for mobile
      if (isMobile && flow.showEnvironmentInformationModal) {
        const fieldToFocus = fieldSelected ? fieldSelected : fields[fields.length - 1];
        fieldToFocus.boundary.geometry.coordinates[0].forEach((coords) => {
          bounds.extend(new LngLat(coords[0], coords[1]));
        });
      } else {
        const allCoordinates = fields
          .map((item) => item.boundary.geometry.coordinates)
          .reduce((prev, current) => [...prev, ...current], [])
          .reduce((prev, current) => [...prev, ...current], []);

        allCoordinates.forEach((coord: Array<number>) => {
          bounds.extend(new LngLat(coord[0], coord[1]));
        });
      }
      runFitBounds = true;
    }

    if (runFitBounds && fieldIdSelected.toString().length === 0) {
      if ((isMobile && !landscape) || !isMobile) {
        map?.fitBounds(bounds, {
          padding: {
            top: isMobile ? 90 : 100,
            bottom: isMobile ? 290 : 50,
            left: isMobile ? 50 : 140,
            right: isMobile ? 50 : 140,
          },
          maxZoom: isMobile ? 16 : 18,
        });
      }
    }
  }, [
    map,
    fields,
    fieldSelected,
    tempBoundaryField,
    currentModeType,
    currentStep,
    isMobile,
    portrait,
    landscape,
    fieldIdSelected,
    showEnvironmentInformationModal,
  ]);

  useEffect(() => {
    if (tempBoundaryField || fields.length > 0) {
      setCenterMapButtonDisable(false);
    } else {
      setCenterMapButtonDisable(true);
    }
  }, [fields, tempBoundaryField]);

  const addControls = (map?: MapRef) => {
    map?.addControl(new CustomControl('stepsControl'), 'bottom-left');
    map?.addControl(new CustomControl('zoomControl'), 'bottom-left');
    map?.addControl(new CustomControl('centerMapControl'), 'bottom-left');
    map?.addControl(new CustomControl('locationControl'), 'bottom-left');
    map?.addControl(new CustomControl('geocoderControl'), 'top-right');
  };

  const displayDrawInfoNotification = useCallback(() => {
    openMapNotification({
      id: 'drawInfoNotification',
      key: 'drawInfoNotification',
      msg: t('Tap anywhere to draw a shape'),
      duration: 0,
      placement: isMobile ? 'top' : 'bottom',
      className: 'toast-notification-draw',
      icon: <img src={Hands} alt="No img found" style={{ position: 'absolute' }} />,
      style: {
        borderRadius: '24px',
        maxWidth: 'max-content',
        height: 'max-content',
        color: '#FFFFFF',
        backgroundColor: 'rgba(20, 21, 28)',
        ...(isMobile && {
          backgroundColor: 'rgba(20, 21, 28, 0.9)',
        }),
      },
    });
  }, []);

  useEffect(() => {
    if (isAtleastAVertex || currentModeType !== ModeTypes.CREATING) {
      notification.close('drawInfoNotification');
    } else {
      displayDrawInfoNotification();
    }
  }, [isAtleastAVertex, currentModeType, i18n.language]);

  const zoomIn = () => {
    map?.getMap().zoomIn();
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
  };

  const zoomOut = () => {
    map?.getMap().zoomOut();
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
  };

  const onUserLocated = (lngLat: LngLat) => {
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
    const mapLoaded = isMobile ? flowMapMobile : flowMap;
    if (mapLoaded) {
      setLongitudeUser(lngLat.lng);
      setLatitudeUser(lngLat.lat);
      setShowUserMarker(true);

      setUserLocation(lngLat);

      if (flow.isAnotherRecommendationCreated && !areExistingFieldsFocusedOnce.current) {
        mapLoaded?.once('moveend', () => {
          areExistingFieldsFocusedOnce.current = true;
        });
        return;
      }
      mapLoaded.flyTo({
        center: [lngLat.lng, lngLat.lat],
        essential: true,
        zoom: 15,
      });
    }
  };

  const onLocationError = () => {
    if (!showNotification) {
      return;
    }

    openMapNotification({
      id: 'preciseLocation',
      className: 'toast-notification-without-icon',
      msg: (
        <StyledNotificationContent>
          <NotificationTextTranslation text={'Your precise location could not be determined.'} />{' '}
          <a target="_blank" rel="noreferrer" href={locationURL}>
            <NotificationTextTranslation text={'LEARN MORE'} />
          </a>
        </StyledNotificationContent>
      ),
      placement: isMobile ? 'top' : 'bottom',
      duration: 10,
      width: 480,
      closeIcon: <IconClose color={colors.neutral40} width={18} height={18} />,
      onClose: () => setShowNotification(true),
      icon: <></>,
    });
    setShowNotification(false);
  };

  const handleGeocodingResult = (geocodingResult: Result) => {
    const mapLoaded = isMobile ? flowMapMobile : flowMap;
    processGeocodingResult(geocodingResult, flags, setUserCountryCode, mapLoaded);
    return '';
  };

  const initializeGeocoding = () => {
    const geocoder = new MapboxGeocoder({
      accessToken: `${process.env.REACT_APP_MAPBOX_API_KEY}`,
      limit: MapsConstants.GEOCODER_LIMIT_SUGGESTIONS,
      minLength: MapsConstants.GEOCODER_MIN_LENGHT,
      countries: isBrazilEnable
        ? MapsConstants.GEOCODER_COUNTRIES_INCLUDING_BRAZIL
        : MapsConstants.GEOCODER_COUNTRIES,
      getItemValue: handleGeocodingResult,
      language: i18n.language,
      placeholder: t('Search Geocoder'),
      clearOnBlur: true,
    });
    geocoder.addTo('#geocoderControl');

    geocoderRef.current = geocoder;

    // replace clear icon
    const geocoderClearButton = document.querySelector(
      '.mapboxgl-ctrl-geocoder--button'
    ) as HTMLElement;
    const icon = document.getElementById('iconClear');
    // eslint-disable-next-line
    geocoderClearButton?.appendChild(icon!);

    const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLElement;

    geocoderClearButton.style.backgroundColor = '#232630';
    geocoderInput.style.color = '#FFF';
    geocoderInput.style.backgroundColor = '#232630';
    geocoderInput.style.borderRadius = '4px';
    geocoderInput.addEventListener('input', () => {
      geocoderClearButton.style.display = 'block';
    });

    geocoder.on('loading', () => {
      geocoderClearButton.style.display = 'none';
    });

    geocoder.on('results', () => {
      geocoderClearButton.style.display = 'block';
    });

    geocoder.on('result', () => {
      if (isMobile) {
        const inputField = document.querySelector(
          '.mapboxgl-ctrl-geocoder--input'
        ) as HTMLInputElement;
        inputField?.blur();
        const closeButtons = Array.from(
          document.querySelectorAll('.syt-antd-notification-notice-close')
        );
        closeButtons.forEach((closeButton) => {
          const element = closeButton as HTMLElement;
          const spanElement = element?.firstChild as HTMLElement;
          spanElement.click();
        });
      }
    });

    if (isMobile) {
      const inputField = document.querySelector(
        '.mapboxgl-ctrl-geocoder--input'
      ) as HTMLInputElement;
      inputField.addEventListener('focus', () => {
        const closeButtons = Array.from(
          document.querySelectorAll('.syt-antd-notification-notice-close')
        );
        closeButtons.forEach((closeButton) => {
          const element = closeButton as HTMLElement;
          const spanElement = element?.firstChild as HTMLElement;
          spanElement.click();
        });
      });
    }
  };

  const existingFieldBoundingBox = useMemo(() => {
    const bounds = new LngLatBounds();
    if (tempBoundaryField) {
      tempBoundaryField?.geometry.coordinates[0].forEach((coords: Array<number>) => {
        bounds.extend(new LngLat(coords[0], coords[1]));
      });
    }
    if (fields.length) {
      const allCoordinates = fields
        .map((item) => item.boundary.geometry.coordinates)
        .reduce((prev, current) => [...prev, ...current], [])
        .reduce((prev, current) => [...prev, ...current], []);

      allCoordinates.forEach((coord: Array<number>) => {
        bounds.extend(new LngLat(coord[0], coord[1]));
      });
    }
    return bounds;
  }, [fields, tempBoundaryField]);

  const handleOnLoadMap = () => {
    const mapLoaded = isMobile ? flowMapMobile : flowMap;
    setMap(mapLoaded);
    setDisplayControls(true);
    addControls(mapLoaded);
    initializeGeocoding();
  };

  const onDbleClickHandler = (e: mapboxgl.MapMouseEvent) => {
    e.originalEvent.stopPropagation();
  };

  const onMouseMoveHandler = (e: mapboxgl.MapMouseEvent) => {
    e.originalEvent.stopPropagation();
    setCoordsEvents({ cursor: e });
    !showFieldForm && setMapToolsOptionSelected(0);
  };

  const checkIntersection = () => {
    if (!userLocation) {
      return;
    }
    const mapLoaded = isMobile ? flowMapMobile : flowMap;
    const bounds = mapLoaded?.getBounds();
    if (bounds) {
      setIntersectLocation(pointInBbox(userLocation, bounds));
    }
  };

  const onMoveHandler = () => {
    onMove?.();
    checkIntersection();
  };

  const onDragEndHandler = () => {
    if (centerMapEnabled) {
      setCenterMapEnabled(false);
    }
    setIsFieldsFitBoundsBlocked(false);
  };

  const getSelectedFieldLayerByPoint = (
    point: mapboxgl.Point
  ): mapboxgl.GeoJSONFeature | null | undefined => {
    const layersId = fields.map((field) => `boundary-${field.id}`);

    const mapLoaded = isMobile ? flowMapMobile : flowMap;
    const fieldsLayer = mapLoaded?.queryRenderedFeatures(point, {
      layers: [...layersId],
    });

    if (!fieldsLayer?.length) {
      return null;
    }

    if (
      fieldsLayer[0].geometry.type !== 'Polygon' ||
      isDrawingBoundaries ||
      fieldsLayer[0].properties === null
    ) {
      return undefined;
    }

    return fieldsLayer[0];
  };

  const onCenterMapButtonClick = () => {
    if (tempBoundaryField || fields.length) {
      const bounds = existingFieldBoundingBox;
      map?.fitBounds(bounds, {
        padding: {
          top: isMobile ? 80 : 100,
          bottom: isMobile ? 90 : 50,
          left: isMobile ? 20 : 140,
          right: isMobile ? 20 : 140,
        },
        maxZoom: isMobile ? 16 : 19,
      });
      setCenterMapEnabled(true);
    }
  };

  const openEnvironmentDrawer = async () => {
    const fieldsDrawed = fields.map((item) => ({
      id: `${item.id}`,
      geometry: {
        type: item.boundary.geometry.type,
        coordinates: [...item.boundary.geometry.coordinates],
      },
    }));
    if (tempBoundaryField?.properties?.id) {
      const fieldIds = fields.map((item) => item.id);
      if (!fieldIds.includes(tempBoundaryField.properties.id)) {
        fieldsDrawed.push({
          id: `${tempBoundaryField.properties?.id}`,
          geometry: {
            type: tempBoundaryField.geometry.type,
            coordinates: tempBoundaryField.geometry.coordinates,
          },
        });
      }
    }

    if (fieldsDrawed.length > 0) {
      const cacheFieldGeometry = fieldsDrawed;
      await ApiDataActions.getFieldSoils(
        fieldsDrawed,
        getCountryCropRegion(countryCode),
        'SYNGENTA',
        JSON.stringify(fieldSoilsCache.current) === JSON.stringify(cacheFieldGeometry)
      );
      fieldSoilsCache.current = cacheFieldGeometry;
    }
    setShowEnvironmentDrawer({ show: true });
    track('environment info', { 'environment info visualization': true });

    if (fieldIdSelected === '' && tempBoundaryField?.properties?.id) {
      setFieldIdSelected({ fieldId: tempBoundaryField?.properties?.id });
    }
  };

  const handleOkSoilsError = () => {
    appDispatcher({
      type: ActionTypes.setFieldSoilsError,
      payload: { error: undefined },
    });
  };

  const drawBoundary = useCallback(({ e }: { e: mapboxgl.MapMouseEvent }) => {
    setCoordsEvents({ clicked: e });
    setMapToolsOptionSelected(0);
    // eslint-disable-next-line
  }, []);

  const selectBoundary = ({ e }: { e: mapboxgl.MapMouseEvent }) => {
    const selectedField = getSelectedFieldLayerByPoint(e.point);
    if (selectedField?.source) {
      const field = getFieldById({
        fieldId: selectedField.source,
        fields: [...fields],
      });
      setFieldIdSelected({ fieldId: field?.id ? `${field.id}` : '' });
      setShowEnvironmentInformationModal({ show: true });
      setFieldSelected({ field });
      setTempBoundaryField({ boundary: field?.boundary });
      setShowMapTools({ show: true });
      if (!showEnvironmentPopup) {
        setShowEnvironmentPopup({ show: true });
      }
    } else {
      setFieldSelected({ field: undefined });
    }
  };

  const onClickHandler = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      setCenterMapEnabled(false);
      e.originalEvent.stopPropagation();
      if (flow.isAnotherRecommendationCreated) {
        return;
      }
      if (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.EDITING) {
        drawBoundary({ e });
      } else {
        selectBoundary({ e });
      }
    },
    [currentModeType]
  );

  const displayEnvironmentInfo = () => {
    openMapNotification({
      id: 'environmentInfoPopup',
      key: 'environmentInfoPopup',
      className: 'toast-notification-with-icon environment-info-popup',
      msg: (
        <StyledNotificationContent>
          <NotificationTextTranslation text={'Environment Information popup message'} />
          {'   '}
          <a
            target="_blank"
            rel="noreferrer"
            className="view-info-link"
            onClick={openEnvironmentDrawer}
            href={undefined}
          >
            <NotificationTextTranslation text={'View info'} />
          </a>
        </StyledNotificationContent>
      ),
      placement: 'bottom',
      duration: 15,
      width: 830,
      onClose: () => {
        setShowEnvironmentPopup({ show: false });
      },
    });
  };

  useEffect(() => {
    geocoderRef.current?.setLanguage(i18n.language);
    geocoderRef.current?.setPlaceholder(t('Search Geocoder'));
  }, [i18n.language, t]);

  useEffect(() => {
    if (!showEnvironmentPopup || isMobile) {
      return;
    }
    displayEnvironmentInfo();
  }, [showEnvironmentPopup]);

  const checkUserIsInDifferentLocation = useMemo(() => {
    return (
      !showInvalidBoundaryModal &&
      fields.length &&
      !isDrawingBoundaries &&
      currentModeType === ModeTypes.SAVING
    );
  }, [showInvalidBoundaryModal]);

  useEffect(() => {
    if (checkUserIsInDifferentLocation) {
      onCenterMapButtonClick();
      setCenterMapEnabled(false);
    }
  }, [
    flow.isAnotherRecommendationCreated,
    currentStep,
    centerMapEnabled,
    flow.mobileActiveTab,
    showInvalidBoundaryModal,
  ]);

  const isWIP =
    currentModeType === ModeTypes.EDITING ||
    currentModeType === ModeTypes.CONFIRMING_AFTER_EDIT ||
    (currentModeType === ModeTypes.CREATING && isAtleastAVertex);

  const center =
    flow.isAnotherRecommendationCreated && !existingFieldBoundingBox.isEmpty()
      ? existingFieldBoundingBox.getCenter()
      : { lat: latitude, lng: longitude };

  const initialViewState = {
    longitude: center.lng,
    latitude: center.lat,
    zoom,
  };

  return (
    <MapReact
      id={mapId}
      onClick={onClickHandler}
      onMove={onMoveHandler}
      onLoad={handleOnLoadMap}
      onDblClick={onDbleClickHandler}
      onMouseMove={onMouseMoveHandler}
      onDragEnd={onDragEndHandler}
      minZoom={minZoom}
      maxZoom={maxZoom}
      initialViewState={initialViewState}
      mapStyle={mapStyle}
      style={{ flexGrow: 1 }}
      cursor="crosshair"
      {...interactiveProps}
    >
      <div data-testid="mapContainer" style={{ display: displayControls ? 'block' : 'none' }}>
        <DrawableMap
          clickedCoordEvent={coordsEvents?.clicked}
          cursorCoordEvent={coordsEvents?.cursor}
        />
        <MapToolsWrapper />
        <StepsControl
          id="stepsControl"
          buttonNextProps={stepControlsProps?.buttonNextProps}
          buttonPreviousProps={stepControlsProps?.buttonPreviousProps}
        />
        <ZoomControl
          id="zoomControl"
          buttonPlusProps={{ 'aria-label': 'Zoom In', onClick: zoomIn }}
          buttonMinusProps={{ 'aria-label': 'Zoom Out', onClick: zoomOut }}
          style={{ display: isDesktop ? 'block' : 'none' }}
        />
        <LocationControl
          buttonProps={{
            id: 'locationControl',
            'aria-label': 'Find my location',
          }}
          onUserLocated={(position: LngLat) => onUserLocated(position)}
          onLocationError={onLocationError}
          isLocationEnabled={Boolean(isLocationEnabled)}
          mapIntersected={intersectLocation}
        />
        <CenterMapControl
          id="centerMapControl"
          isDisabled={centerMapButtonDisable}
          onCenterMapButtonClick={onCenterMapButtonClick}
          isActive={centerMapEnabled}
        />
        <StyledDivGeocoder
          id="geocoderControl"
          style={{ display: flow.currentStep === FlowSteps.STEP1 ? 'block' : 'none' }}
          className={isWIP ? 'geocoder-disabled' : ''}
        >
          <IconCloseRounded id="iconClear" />
        </StyledDivGeocoder>
      </div>
      {showUserMarker && (
        <MarkerReact style={{ zIndex: 1 }} latitude={latitudeUser} longitude={longitudeUser}>
          <StyledMarker />
        </MarkerReact>
      )}
      {fieldSoilsLoading && mapInteractive && <Spinner data-testid="soil-api-spinner" />}
      {arableLandLoading && currentModeType === ModeTypes.CREATING && (
        <Spinner data-testid="arable-api-spinner" />
      )}
      {fieldSoilsError && (
        <ConfirmationModal
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          confirmButtonText={t('Ok')}
          onClickConfirm={handleOkSoilsError}
        />
      )}
    </MapReact>
  );
};
export default Maps;
