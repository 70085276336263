import { useEffect, useState } from 'react';
import { LngLat } from 'mapbox-gl';

import ButtonCircle from 'components/Buttons/ButtonCircle/ButtonCircle';
import { StyledIconMyLocation, StyledIconUserLocated } from './LocationControl.styles';
import { ButtonProps, TooltipProps } from 'antd';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import track from 'utils/amplitudeWrapper';
import { useFlowActions } from 'context/actions/flowActions';
import { trackGTMEvent } from 'utils/createGTMEvent';
import { useAppState } from 'context/AppState';

interface IProps {
  buttonProps?: ButtonProps;
  tooltipProps?: TooltipProps;
  onUserLocated: (location: LngLat) => void;
  onLocationError?: () => void;
  isLocationEnabled: boolean;
  mapIntersected?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const LocationControl = ({
  buttonProps,
  tooltipProps,
  onUserLocated,
  onLocationError,
  isLocationEnabled,
  mapIntersected = false,
  onClick,
  style,
}: IProps): JSX.Element => {
  const flowActions = useFlowActions();
  const [location, setLocation] = useState<LngLat>(
    new LngLat(MapsConstants.LONGITUDE_DEFAULT, MapsConstants.LATITUDE_DEFAULT)
  );
  const [isUserLocated, setIsUserLocated] = useState(false);
  const [firstTimeFlag, setFirstTimeFlag] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    flow: { localCountry },
    recommendationForm: { countryCode },
  } = useAppState();

  const onSuccessHandler = (position: GeolocationPosition) => {
    track('user location', { 'location accepted': true });
    trackGTMEvent('user_location', countryCode, localCountry);
    const { latitude, longitude } = position.coords;
    const lngLat = new LngLat(longitude, latitude);
    setLocation(lngLat);
    setIsUserLocated(true);
    onUserLocated(lngLat);
    setShowActive(true);
    flowActions.setShowSpinner({ show: false });
  };

  const onErrorHandler = () => {
    track('user location', { 'location accepted': false });
    onLocationError?.();
    flowActions.setShowSpinner({ show: false });
  };
  const onGetUserLocation = () => {
    if (isLocationEnabled && navigator.geolocation) {
      flowActions.setShowSpinner({ show: true });
      if (!isUserLocated) {
        const timeoutId = setTimeout(() => {
          flowActions.setShowSpinner({ show: false });
        }, 5000); // 5 seconds timeout

        navigator.geolocation.getCurrentPosition(
          (position) => {
            clearTimeout(timeoutId);
            onSuccessHandler(position);
          },
          () => {
            clearTimeout(timeoutId);
            onErrorHandler();
          },
          { timeout: 5000 } // 5 seconds timeout for geolocation API
        );
        setShowTooltip(true);
      } else {
        onUserLocated(location);
        setShowActive(true);
        flowActions.setShowSpinner({ show: false });
        setShowTooltip(false);
      }
    }
    onClick?.();
  };

  useEffect(() => {
    if (isLocationEnabled) {
      onGetUserLocation();
    }
    // eslint-disable-next-line
  }, [isLocationEnabled]);

  useEffect(() => {
    if (!mapIntersected && firstTimeFlag) {
      setShowActive(false);
    }
    if (!mapIntersected) {
      setFirstTimeFlag(true);
    } else if (mapIntersected && !firstTimeFlag) {
      setFirstTimeFlag(true);
      setShowActive(true);
    }
    // eslint-disable-next-line
  }, [mapIntersected]);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 10000);
    }
  }, [showTooltip]);

  return (
    <ButtonCircle
      buttonProps={buttonProps}
      {...(showTooltip && !isUserLocated
        ? {
          tooltipProps: {
            placement: 'topLeft',
            style: {
              zIndex: '1 !important',
            },
            className: 'tooltip-test',
            ...tooltipProps,
          },
        }
        : {})}
      iconCustom={showActive ? StyledIconUserLocated : StyledIconMyLocation}
      onClick={onGetUserLocation}
      style={{
        backgroundColor: `${showActive ? colors.green60 : colors.neutral90}`,
        color: `${showActive ? colors.neutral10 : colors.neutral90}`,
        borderRadius: style?.borderRadius,
      }}
    />
  );
};

export default LocationControl;
